<template>
  <v-list>
    <v-list-item>
      <ListIcon><v-icon>mdi-clock-outline</v-icon></ListIcon>
      <v-list-item-content>
        <v-list-item-title>{{ value.day.description }} </v-list-item-title>
        <v-list-item-subtitle>{{ time }} </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <ListIcon><v-icon>mdi-book-open-variant</v-icon></ListIcon>
      <v-list-item-content>
        <v-list-item-title
          >{{ value.subject.shortDescription }}
        </v-list-item-title>
        <v-list-item-subtitle
          >{{ value.subject.type.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <SchoolClassItems :value="value.schoolClasses"></SchoolClassItems>
    <RoomItems :value="value.rooms"></RoomItems>
    <PersonItems :value="value.teachers"></PersonItems>
  </v-list>
</template>
<script>
import { defineComponent } from "vue";
import { formatTime } from "common/utils/date";
import PersonItems from "common/components/PersonItems.vue";
import RoomItems from "common/components/RoomItems.vue";
import SchoolClassItems from "common/components/SchoolClassItems.vue";
export default defineComponent({
  name: "TimetableLessonDetail",
  components: { PersonItems, RoomItems, SchoolClassItems },
  props: {
    value: null,
  },
  computed: {
    time() {
      return `${formatTime(this.value.startTime)} – ${formatTime(
        this.value.endTime
      )}`;
    },
  },
});
</script>
