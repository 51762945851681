<template>
  <div>
    <SchoolClassItem
      icon="mdi-account-group"
      v-if="single"
      :value="value[0]"
    ></SchoolClassItem>
    <v-list-group v-else :value="false">
      <template v-slot:activator>
        <ListIcon><v-icon>mdi-account-group</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-title>{{ codes }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <SchoolClassItem
        v-for="item in value"
        :key="item.id"
        :value="item"
      ></SchoolClassItem>
    </v-list-group>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import SchoolClassItem from "common/components/SchoolClassItem.vue";
import { schoolClassCode } from "common/utils/school";

export default defineComponent({
  name: "SchoolClassItems",
  props: {
    value: null,
  },
  components: {
    SchoolClassItem,
  },
  computed: {
    codes() {
      return schoolClassCode(this.value);
    },
    single() {
      return this.value && this.value.length === 1;
    },
  },
});
</script>
